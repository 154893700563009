import logo from "./logo.svg";
import "./App.css";
import React, { createContext, useEffect, useState } from "react";
import { QrReader } from "react-qr-reader";
import ListGroup from "react-bootstrap/ListGroup";
import { Routes, Route, Link, Navigate } from "react-router-dom";
import Home from "./routes/Home";
import CreateQr from "./routes/CreateQr";
import ReadQr from "./routes/ReadQr";
import Generator from "./routes/Generator";
import Axios from "axios";
import { GetCodes, getCodes } from "./routes/hooks";
export const Generated = createContext();
export const Lis = createContext();
export const Name = createContext();
export const Codes = createContext();
export const Change = createContext();
export const Url = createContext();
function App() {
  const [gen, setGen] = useState("No data Found");
  const [lis, setLis] = useState([]);
  const [name, setName] = useState("");
  const [codes, setCodes] = useState([]);
  const [change, setChange] = useState(false);
  const [url, setUrl] = useState("https://verifier.brunowalter.repl.co");

  useEffect(() => {
    console.log("codes are done");
    console.log(codes);
  }, [codes]);

  return (
    <div className="App">
      <nav>
        <Link to="/">Home</Link> <Link to="/create">Create</Link>{" "}
        <Link to="/read">Read</Link>{" "}
      </nav>
      <Url.Provider value={[url, setUrl]}>
        <Change.Provider value={[change, setChange]}>
          <Codes.Provider value={[codes, setCodes]}>
            <Generated.Provider value={[gen, setGen]}>
              <Lis.Provider value={[lis, setLis]}>
                <Name.Provider value={[name, setName]}>
                  <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/create" element={<CreateQr />} />
                    <Route path="/read" element={<ReadQr />} />
                    <Route path="/gen" element={<Generator />} />
                    <Route path="*" element={<Home />} />
                  </Routes>
                </Name.Provider>
              </Lis.Provider>
            </Generated.Provider>
          </Codes.Provider>
        </Change.Provider>
      </Url.Provider>
    </div>
  );
}

export default App;
