import React, { useContext } from "react";
import Table from "react-bootstrap/Table";

import ReactDOM from "react-dom";
import QRCode from "qrcode.react";
import { useState, useEffect } from "react";
import { Change, Codes, Lis, Name } from "../App";
import Generator from "./Generator";
import { GetCodes } from "./hooks";

export default function CreateQr() {
  const [qCode, setQCode] = useContext(Lis);
  const [show, setShow] = useState(false);
  const [name, setName] = useContext(Name);
  const [can, setCan] = useState("");
  const [codes, setCodes] = useContext(Codes);
  const [change, setChange] = useContext(Change);

  useEffect(() => {
    console.log("these are the codes");
    console.log(codes);
  }, [qCode]);
  useEffect(() => {
    if (qCode.length > 0) {
      setShow(true);
    } else {
      console.log("nott rue");
    }
  }, [qCode]);
  useEffect(() => {
    if (show == true) {
      setCan(document.getElementById("qr-gen"));
      console.log("this is happenning");
      console.log(can);
    }
  }, [show]);
  const downloadQRCode = () => {
    // Generate download with use canvas and stream
    const canvas = document.getElementById("qr-gen");
    console.log(typeof canvas);
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `${name}.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const downloadQRCode2 = (a, b) => {
    // Generate download with use canvas and stream
    const canvas = document.getElementById(a);
    console.log(typeof canvas);
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `${b}.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };
  return (
    <div style={{ backgroundColor: "white" }}>
      <GetCodes />
      <Generator />

      {show == true ? (
        <div style={{ textAlign: "center", padding: "1rem", color: "black" }}>
          <QRCode
            id="qr-gen"
            level={"H"}
            includeMargin={true}
            size={256}
            style={{
              height: "auto",
              maxWidth: "100%",
              width: "100%",
              padding: "1rem",
            }}
            value={qCode.join("")}
            viewBox={`0 0 256 256`}
          />
          <button className="btn btn-primary" onClick={downloadQRCode}>
            Download
          </button>
        </div>
      ) : (
        ""
      )}
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Name</th>

            <th>QR</th>
          </tr>
        </thead>
        {codes.length > 0 &&
          codes.map((a) => (
            <>
              {a.verified == "false" && (
                <tbody>
                  <tr>
                    <td>{a.name}</td>

                    <td
                      style={{
                        padding: "auto",
                        margin: "auto",
                      }}
                    >
                      <QRCode
                        id={a.id}
                        level={"H"}
                        includeMargin={true}
                        size={256}
                        style={{
                          height: "auto",
                          maxWidth: "100%",
                          width: "100%",
                          padding: "1rem",
                        }}
                        value={a.code}
                        viewBox={`0 0 256 256`}
                      />
                      <button
                        className="btn btn-primary"
                        onClick={() => downloadQRCode2(a.id, a.name)}
                      >
                        Download
                      </button>
                    </td>
                  </tr>
                </tbody>
              )}
            </>
          ))}
      </Table>
    </div>
  );
}
