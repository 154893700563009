import React, { useContext, useEffect } from "react";
import { Change, Codes, Name, Url } from "../App";
import Axios from "axios";

export function dude() {
  alert("this is a test success");
  console.log("test is working");
}

export const GetCodes = () => {
  const [a, setA] = useContext(Codes);
  const [change, setChange] = useContext(Change);
  const [url, setUrl] = useContext(Url);

  useEffect(() => {
    const a = async () =>
      await Axios.get(url + "/api/get").then((response) => {
        setA(response.data);
      });
    a();
  }, [change]);
};
